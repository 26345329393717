<template>
    <section class="section">
        <div class="container">
            <UpcomingContainer :transactions="transactions" @create="create" @book="book" @filterYearChanged="onFilterYearChanged" @filterMonthChanged="onFilterMonthChanged" />
        </div>
        <!-- <Dialog /> -->
    </section>
</template>

<script>
import Api from "../taxAccount.api";
import UpcomingContainer from "./ui/upcoming.container2";
// import Dialog from "@/dialog/dialog.vue";
//import dialog from "@/dialog/dialog";
export default {
    data: function() {
        return {
            transactions: [],
            filterYear: 2021,
            filterMonth: 1,
        };
    },
    components: { UpcomingContainer },
    async created() {
        this.transactions = await Api.getUpcoming2(this.filterYear, this.filterMonth);
        document.title = "Skattekonto - Kommande ny";
    },
    methods: {
        async onFilterYearChanged(year) {
            this.filterYear = year;
            this.transactions = [];
            this.transactions = await Api.getUpcoming2(this.filterYear, this.filterMonth);
        },
        async onFilterMonthChanged(month) {
            this.filterMonth = month;
            this.transactions = [];
            this.transactions = await Api.getUpcoming2(this.filterYear, this.filterMonth);
        },
        async create(data) {
            var sum = data.reduce(function(prev, cur) {
                const amount = parseFloat(cur.amount.replace(",", ".").replaceAll(" ", ""));
                return prev + amount;
            }, 0);
            if (sum >= 0) {
                await this.$dialog.html().alert(`Det blir ett överskott på kontot med ${sum} SEK, inget skatteuppdrag kommer skapas.`);
            } else {
                const confirmed = await this.$dialog
                    .title("Skatteupdrag")
                    .html()
                    .confirm(`Du kommer skapa ett skatteuppdrag på <strong>${-sum} SEK</strong>`);
                if (confirmed) {
                    const result = await Api.createUpcoming2(data);
                    const type = result.messageType == 0 ? "success" : result.messageType == 1 ? "info" : "error";
                    this.$toasted.show(result.message, {
                        theme: "bubble",
                        duration: null,
                        position: "bottom-center",
                        type: type,
                        keepOnHover: true,
                        action: {
                            text: "Cancel",
                            onClick: (e, toastObject) => {
                                toastObject.goAway(0);
                            },
                        },
                    });
                    this.transactions = [];
                    this.transactions = await Api.getUpcoming2(this.filterYear, this.filterMonth);
                }
            }
        },
        async book(data) {
            await Api.bookUpcoming(data);
            this.transactions = [];
            this.transactions = await Api.getUpcoming2(this.filterYear, this.filterMonth);
        },
    },
};
</script>
