<template>
    <div>
        <List :items="transactions" :scroll="true" :search="false" :headers="headers" style="font-size: 13px;" />
    </div>
</template>

<script>
import List from "../../../../components/list2";
export default {
    components: {
        List,
    },
    props: ["transactions"],
    data: function() {
        return {
            headers: [
                { name: "", prop: "check", width: "20px", check: true },
                { name: "Nr", prop: "number", width: "50px" },
                { name: "Företag", prop: "name" },
                { name: "Text", prop: "text" },
                { name: "Skattetyp", prop: "taxType" },
                { name: "Kommande", prop: "upcoming" },
                { name: "Status", prop: "taxStatus" },
                { name: "Ver1", prop: "paymentVoucherNumber" },
                { name: "Ver2", prop: "taxWithdrawalVoucherNumber" },
                { name: "Id", prop: "id", width: "50px" },
                { name: "Datum SKV", prop: "date", width: "100px" },
                { name: "Saldo SKV", prop: "balance", width: "150px", align: "right" },
                { name: "Belopp SKV", prop: "amount", width: "150px", align: "right" },
            ],
            options: [],
        };
    },
    methods: {},
    computed: {},
};
</script>
<style scoped>
.linkcolorok {
    background-color: #ccddcc !important;
}
.linkcolordiff {
    background-color: #f7d3ab !important;
}
.linkcolorreceipt {
    background-color: #abccf7 !important;
}
.selected {
    border-style: solid !important;
    border-width: 2px !important;
    border-color: #000000 !important;
}
th {
    background: white;
    position: sticky;
    top: 0;
}
</style>
