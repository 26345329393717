import Vue from "vue";

export default {
    getAccountsList: async () => {
        const response = await Vue.axios.get(`taxAccount/list`);
        return response.data;
    },
    getClientAccounts: async clientId => {
        const response = await Vue.axios.get(`taxAccount/list/${clientId}`);
        return response.data;
    },
    getAccounts: async () => {
        const response = await Vue.axios.get(`taxAccount`);
        return response.data;
    },
    getUpcoming: async (year, month, companyType) => {
        const response = await Vue.axios.get(`taxAccount/upcoming?year=${year}&month=${month}&companyType=${companyType}`);
        return response.data;
    },
    getUpcoming2: async (year, month) => {
        const response = await Vue.axios.get(`taxAccount/upcoming2?year=${year}&month=${month}`);
        return response.data;
    },
    createUpcoming: async data => {
        const response = await Vue.axios.post(`taxAccount/upcoming/create`, data);
        return response.data;
    },
    createUpcoming2: async data => {
        const response = await Vue.axios.post(`taxAccount/upcoming2/create`, data);
        return response.data;
    },
    bookUpcoming: async data => {
        await Vue.axios.post(`taxAccount/upcoming/book`, data);
        return;
    },
    getAccount: async clientId => {
        const response = await Vue.axios.get(`taxAccount/${clientId}`);
        return response.data;
    },
};
