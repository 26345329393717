<template>
    <div class="columns">
        <div class="column is-full">
            <Stack>
                <Columns align-y="center">
                    <TextBase>Skattetyp:</TextBase>
                    <DropDown :options="taxTypes" state="ready" v-model="taxType" style="margin-left: 10px; width: 100%" />
                    <TextBase>Företagstyp:</TextBase>
                    <DropDown :options="companyTypes" state="ready" v-model="companyType" style="margin-left: 10px; width: 100%" />
                    <TextBase>Startmånad:</TextBase>
                    <DropDown :options="months" state="ready" v-model="filterMonth" style="margin-left: 10px; width: 100%" />
                </Columns>
            </Stack>
            <div v-if="false" style="display: flex;">
                <RadioButtonList :entries="viewOptions" label="Filtrera" :horizontal="true" @change="onChangeViewOption" style="margin-left:10px;" />
                <DropDown :options="years" @on-change="onYearChange" state="ready" :value="filterYear" style="margin-left: 10px;" />
                <DropDown :options="months" @on-change="onMonthChange" state="ready" :value="filterMonth" style="margin-left: 10px;" />
            </div>
            <TransactionList :transactions="transactionsFilteredByClient" />
            <i style="margin: 10px 0 0 8px;" class="fas fa-flip-horizontal fa-level-up-alt" />
            <button style="margin: 10px 0 0 10px;" v-if="true" class="button is-info is-outlined is-small" @click="click">
                Skapa skatteuppdrag
            </button>
            <button style="margin: 10px 0 0 10px;" v-if="true" class="button is-info is-outlined is-small" @click="clickBookSkv">
                Bokför 1630
            </button>
        </div>
    </div>
</template>

<script>
import TransactionList from "./transactionList";
import RadioButtonList from "../../../../components/radioButtonList";
import Stack from "../../../../components/layout/Stack.vue";
import Columns from "../../../../components/layout/Columns.vue";
import Column from "../../../../components/layout/Column.vue";
import Input from "../../../../components/baseInput";
import TextBase from "../../../../components/content/Text.vue";
import TextArea from "../../../../components/textArea.vue";
import DropDown from "../../../../components/dropdown";

export default {
    props: ["transactions"],
    components: {
        TransactionList,
        RadioButtonList,
        Stack,
        Columns,
        // eslint-disable-next-line vue/no-unused-components
        Column,
        // eslint-disable-next-line vue/no-unused-components
        Input,
        TextBase,
        // eslint-disable-next-line vue/no-unused-components
        TextArea,
        DropDown,
    },
    data: function() {
        return {
            viewOptions: [
                { name: "Alla", value: "all" },
                { name: "Preliminärskatt", value: "preliminaryTax" },
                { name: "Skatt", value: "tax" },
                { name: "Slutlig skatt enskilda firmor", value: "taxprivate" },
                { name: "Arbetsg.avg.", value: "aga" },
                { name: "Moms", value: "vat" },
            ],
            viewOption: "all",
            years: [
                { name: "2019", value: 2019 },
                { name: "2020", value: 2020 },
                { name: "2021", value: 2021 },
            ],
            months: [
                { name: "Alla", value: 0 },
                { name: "Jan", value: 1 },
                { name: "Feb", value: 2 },
                { name: "Mar", value: 3 },
                { name: "Apr", value: 4 },
                { name: "Maj", value: 5 },
                { name: "Jun", value: 6 },
                { name: "Jul", value: 7 },
                { name: "Aug", value: 8 },
                { name: "Sep", value: 9 },
                { name: "Okt", value: 10 },
                { name: "Nov", value: 11 },
                { name: "Dec", value: 12 },
            ],
            companyTypes: [
                { name: "Alla", value: 0 },
                { name: "Aktiebolag", value: 5 },
                { name: "Enskild firma", value: 1 },
                { name: "Handelsbolag", value: 2 },
            ],
            taxTypes: [
                { name: "Alla", value: 0 },
                { name: "Preliminärskatt", value: "Preliminärskatt" },
                { name: "Skatt föregående år", value: "Skatt föregående år" },
                { name: "Arbetsgivaravgift/Löneskatt", value: "Arbetsgivaravgift/Löneskatt" },
                { name: "Moms", value: "Moms" },
            ],
            filterYear: 2021,
            taxType: 0,
            companyType: 0,
            filterMonth: 0,
        };
    },
    watch: {},
    methods: {
        onYearChange(year) {
            this.$emit("filterYearChanged", year);
        },
        onMonthChange(month) {
            this.$emit("filterMonthChanged", month);
        },
        click() {
            const data = this.transactionsMaped.filter(item => item.check === true);
            this.$emit("create", data);
        },
        clickBookSkv() {
            const data = this.transactionsMaped.filter(item => item.check === true);
            this.$emit("book", data);
        },
        onChangeViewOption: function(item) {
            this.viewOption = item.value;
        },
    },

    computed: {
        transactionsMaped: function() {
            return this.transactions.map(item => ({
                clientId: item.clientId,
                taxAccountTransactionId: item.taxAccountTransactionId,
                number: item.number,
                name: item.name,
                companyType: item.companyType,
                text: item.text,
                taxStatus: item.taxStatus,
                date: item.date,
                amount: this.$options.filters.swedishNumberFormat(item.amount),
                balance: this.$options.filters.swedishNumberFormat(item.accountBalance),
                amountRegistered: this.$options.filters.swedishNumberFormat(item.amountRegistered),
                check: false,
                id: item.id,
                paymentVoucherNumber: item.paymentVoucherNumber,
                taxWithdrawalVoucherNumber: item.taxWithdrawalVoucherNumber,
                upcoming: item.upcoming ? "Kommande" : "Utförd",
                taxType: item.taxType,
            }));
        },
        transactionsFiltered() {
            return this.transactionsMaped.filter(item => {
                return (this.companyType == 0 || item.companyType == this.companyType) && (this.taxType == 0 || item.taxType == this.taxType);
            });
        },
        transactionsFilteredByClient() {
            return this.isGlobalClientSelected ? this.transactionsFiltered.filter(trans => trans.clientId === this.$store.state.topBar.selectedClient.value) : this.transactionsFiltered;
        },
        isGlobalClientSelected() {
            return this.$store.getters["topBar/isClientSelected"];
        },
        // transactionsFiltered2: function() {
        //     if (this.viewOption == "taxprivate") {
        //         return this.transactionsMaped.filter(item => {
        //             return (
        //                 item.companyType == 1 &&
        //                 (item.text.toLowerCase() == "avdragen skatt" ||
        //                     item.text.toLowerCase() == "slutlig skatt" ||
        //                     item.text.toLowerCase() == "tillgodoförd debiterad preliminärskatt" ||
        //                     item.text.toLowerCase() == "preliminär skattereduktion för rot-/rutarbete")
        //             );
        //         });
        //     } else {
        //         return this.transactionsMaped.filter(item => {
        //             return (
        //                 (this.viewOption == "preliminaryTax" && item.text == "Debiterad preliminärskatt") ||
        //                 (this.viewOption == "vat" && item.text.toLowerCase().includes("moms")) ||
        //                 (this.viewOption == "tax" && item.text.toLowerCase().includes("skatt") && !item.text.toLowerCase().includes("prel")) ||
        //                 (this.viewOption == "aga" && item.text.toLowerCase().includes("arbetsgivar")) ||
        //                 this.viewOption == "all"
        //             );
        //         });
        //     }
        // },
    },
};
</script>
